import './../css/App.css';

//TEMPLATE
import Header from './header'
import LoginRegister from './LoginRegister'
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <div className='contenedor'>
      <Header/>
      <LoginRegister/>
      <div className='calltoaction'>
        <h2>¿TIENES DUDAS O CONSULTAS SOBRE EL SERVICIO?</h2>
        <div className='btn_cta'>CONTACTÁ CON NOSOTROS</div>
      </div>
      </div>
    </div>
  );
}

export default App;
