import './../../css/Comocomprar.css';

//TEMPLATE
import Header from '../header'
import LoginRegister from '../LoginRegister'
import Hero from '../componentes/Hero';

import PreguntasItem from '../componentes/preguntasItem';

import comocomprar from './../../assets/img/comocomprar.png'
import CtaDudas from './../componentes/CtaDudas'
import cruz from './../../assets/img/cruz.svg'

import { Link } from 'react-router-dom';

function NuestrosServicios() {
  let datosShow = {
    titulo : "CÓMO COMPRAR",
    contenido:"¡Comprar en una tienda online es muy fácil! Te explicamos a continuación en unos simples pasos. En caso de dudas o consultas, contactá con nosotros vía WhatsApp o envianos un correo a compras@idealbox.com.py",
    img:comocomprar
  }

  let dataPreguntas = [
    {
      pregunta:"¿CÓMO ABRO UNA CASILLA?",
      respuesta:<p>Para tener tu casilla tenés que registrarte con todos tus datos haciendo <u><Link to={'/register'}>click acá</Link></u> .</p>
    },
    {
      pregunta:"¿CUÁLES SON LOS REQUISITOS?",
      respuesta:"Solo tenés que completar el formulario de registro y aceptar nuestros Términos y Condiciones, que regulan las obligaciones y responsabilidades de cada parte en la prestación del servicio."
    },
    {
      pregunta:"¿TIENE ALGÚN COSTO LA CASILLA?",
      respuesta:"Abrir tu casilla es totalmente GRATIS y no tiene costo de mantenimiento."
    },
    {
      pregunta:"¿CÓMO SE COMPRA POR INTERNET?",
      respuesta:"El proceso de compra varía de acuerdo al comercio. Si es tu primera vez comprando en línea, podés asesorarte con nuestro equipo de atención al cliente vía WhatsApp haciendo click acá o en nuestro formulario de contacto."
    },
    {
      pregunta:"¿EN QUÉ PÁGINAS PUEDO COMPRAR?",
      respuesta: <div> 
        <p>Podés comprar en cualquier tienda que haga envíos a nuestra dirección en Miami, Florida - USA. <br /> Te dejamos sugerencias de páginas de compra para todo tipo de artículos:</p> 
        <ul>
          <li>Amazon</li>
          <li>Best Buy</li>
          <li>AliExpress</li>
          <li>Walmart
          <li>Target</li>
          </li>
        </ul>
        <p>Para más recomendaciones, comunicate con nuestro equipo de atención al cliente.</p>
        </div>
    },
    {
      pregunta:"¿QUÉ ARTÍCULOS NO PUEDEN ENVIARSE?",
      respuesta:<div> 
        <p>Existe una serie de artículos que no se pueden importar:</p>
      <ul>
        <li>Materiales obscenos, pornográficos o inmorales.</li>
        <li>Drogas ilegales.</li>
        <li>Plantas y/o animales.</li>
        <li>Productos químicos inflamables, explosivos o corrosivos.</li>
        <li>Armas de fuego, municiones, acccesorios y partes.</li>
        <li>Bienes considerados patrimonios culturales..</li>
        <li>Dinero en efectivo, cheques, lingotes de oro y documentos de identidad.</li>
      </ul>
      <p>Para más información, consultá con nuestros representantes de atención al cliente.</p>
      </div>
    },
    {
      pregunta:"¿CÓMO PUEDO ASEGURAR MIS PAQUETES?",
      respuesta:"No tiene ningún costo. Abrir tu casilla es totalmente GRATIS."
    },
    {
      pregunta:"¿QUÉ ES EL SHIPPING ADDRESS?",
      respuesta:"Es la dirección física en donde la tienda te enviará tu compra. Al suscribirte a IDEALBOX automáticamente recibís una dirección física (casilla) en EEUU."
    },
    {
      pregunta:"¿QUÉ ES EL TRACKING NUMBER?",
      respuesta:"Es un número de identificación único o código asignado a tu paquete, se genera una vez que el mismo haya sido envíado. Son útiles para conocer la ubicación de sus compras en el tiempo."
    },
    {
      pregunta: '¿CÓMO RECIBO EL PAQUETE?',
      respuesta: 'Hacemos la entrega de paquetes puerta a puerta. Una vez tengamos listo tu paquete nos comunicaremos contigo para coordinar la entrega. Si te encontrás en el interior del país, enviamos el paquete por encomienda/transportadora, quedando a tu cargo el costo del envío.'
    }
  ]

  
  return (
    <div className="App">
      <div className='contenedor'>
      <Header/>
        <Hero datosShow={datosShow}></Hero>
        
        <section id="comprarfacil">
          <h3 className='titulo_degradado'> COMPRAR NUNCA FUE TAN FÁCIL</h3>
          <div className="itemDestc">
            <div className="icon_side">
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 21.3333L24 29.3333L45.3333 8" stroke="#FFC800" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M45.3334 24.0001V40.0001C45.3334 41.4146 44.7715 42.7711 43.7713 43.7713C42.7711 44.7715 41.4145 45.3334 40 45.3334H8.00002C6.58553 45.3334 5.22898 44.7715 4.22878 43.7713C3.22859 42.7711 2.66669 41.4146 2.66669 40.0001V8.00008C2.66669 6.58559 3.22859 5.22904 4.22878 4.22885C5.22898 3.22865 6.58553 2.66675 8.00002 2.66675H32" stroke="#FFC800" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div className="text_side">
              <h3>SUSCRIBITE</h3>
              <p>
              Abrí tu casilla de IDEALBOX totalmente GRATIS. Con tu casilla contás con una dirección física en los Estados Unidos donde las tiendas te enviarán tus compras. Una vez terminado el registro sin costo recibirás un correo electrónico de confirmación automáticamente, en donde tenés que activar la cuenta y listo. ¡Ya podés empezar a comprar!
              </p>
            </div>
          </div>
          <div className="itemDestc">
            <div className="icon_side">
            <svg width="48" height="52" viewBox="0 0 48 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00002 50.0002C10.9455 50.0002 13.3334 47.6123 13.3334 44.6668C13.3334 41.7213 10.9455 39.3335 8.00002 39.3335C5.0545 39.3335 2.66669 41.7213 2.66669 44.6668C2.66669 47.6123 5.0545 50.0002 8.00002 50.0002Z" stroke="#39BBDD" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M37.3333 50.0002C40.2789 50.0002 42.6667 47.6123 42.6667 44.6668C42.6667 41.7213 40.2789 39.3335 37.3333 39.3335C34.3878 39.3335 32 41.7213 32 44.6668C32 47.6123 34.3878 50.0002 37.3333 50.0002Z" stroke="#39BBDD" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M37.3334 39.3333H8.00002V2H2.66669" stroke="#39BBDD" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8 7.3335L45.3333 10.0002L42.6667 28.6668H8" stroke="#39BBDD" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </div>
            <div className="text_side">
              <h3>COMPRÁ</h3>
              <p>
              Con tu casilla de IDEALBOX podés empezar a comprar en las mejores tiendas online. Una vez que elijas el producto, debés colocar la dirección de tu casilla como dirección de envío o shipping address.
              </p>
            </div>
          </div>

          <div className="itemDestc">
            <div className="icon_side">
            <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.66669 14V38L24 50V26M2.66669 14L24 2L45.3334 14M2.66669 14L24 26M45.3334 14V26M45.3334 14L24 26M32 42H50.6667M50.6667 42L42.6667 34M50.6667 42L42.6667 50" stroke="#FFC800" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            </div>
            <div className="text_side">
              <h3>RECIBÍ</h3>
              <p>
              Una vez finalizada la compra, el vendedor la envía a nuestro depósito donde recibimos tus paquetes, procesamos su ingreso al sistema y los enviamos a Paraguay en el primer vuelo disponible de la semana. Una vez en Paraguay, nos encargamos de todos los procesos correspondientes y nos comunicamos contigo para coordinar la entrega.
              </p>
            </div>
          </div>
        </section>
        
        <div className='mercanciaprohibidas'>
          <h3 className="titulo_degradado">tipo de mercaderías prohibidas</h3>
          <div className='listado'>
            <p><img src={cruz} alt="" /> Materiales obscenos, pornográficos o inmorales, drogas, plantas, animales, productos perecederos.</p>
            <p><img src={cruz} alt="" /> Productos químicos inflamables, explosivos corrosivos.</p>
            <p><img src={cruz} alt="" /> Armas blancas, de fuego y municiones de cualquier tipo.</p>
            <p><img src={cruz} alt="" /> Accesorios o partes. Bienes considerados patrimonios culturales.</p>
          </div>
        </div>

        <div className='queesvolumen'>
          <h3 className='titulo_degradado'>?qué es volumen?</h3>
          <p>El Peso Volumétrico es una conversión a KG de un paquete basada en las dimensiones del mismo. La forma de calcularlo es: Largo (cm) x Alto (cm) x Ancho (cm) / 3000. El Peso Real es el peso real en KG de un paquete. El Peso Aforado es el peso mayor entre el Real y el Volumétrico. Las tarifas por peso se calcularán tomando el Peso Aforado, es decir, el mayor entre el Volumétrico y el Real.          </p>
          <ul>
            <li>A = Alto</li>
            <li>B = Ancho</li>
            <li>C = Largo</li>
          </ul>
          <p >  <span className='amarillo'>Ejemplo:</span> <br /> <br />
          30 cm x 50 cm x 70 = 105.000 (cm3) <br /> <br />
          105.000/6000 = 17,50 (Peso volumentrico en KG). <br /> <br />
          Si este envío de ejemplo pesara 10 KG significa que tiene un exceso de volumen de 7.50 kg (17,50-10 = 7,50). <br /> <br />
          El volumen solo afecta al transporte aéreo y no a los despachos aduaneros.
          </p>
        </div>


        <PreguntasItem dataPreguntas={dataPreguntas} />



        <CtaDudas/>
      </div>
    </div>

    
  );
}

export default NuestrosServicios;
