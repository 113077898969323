import React, { lazy, Suspense, useEffect, useState } from "react";
import { Routes , Route, Navigate} from "react-router-dom";

import loadable from '@loadable/component';

import Estructura from './estructura'


const Cuenta = loadable(() => import('./cuenta'));
const MetodosPago = loadable(() => import('./metodos-pago'));
const Paquetes = loadable(() => import('./paquetes'));
const Comprobantes = loadable(() => import('./Comprobantes'));
// const isAuthenticated = localStorage.getItem("token")
// console.log(isAuthenticated)
export const Micuenta = ({isAuthenticated}) => {
  // let [isAuthenticated,setIsAuth] = useState(null)
	// useEffect(()=>{
  //   setIsAuth(isAuthenticated)
  //   console.log(isAuthenticated)
  // },[])
  return (
    <Suspense>
      <Routes>
        <Route path={`/`} element={ isAuthenticated ? (<Estructura><Cuenta/></Estructura>) : (<Navigate to={{ pathname: '/login'}}/>)}  />
        <Route  exact path={`paquetes`} element={ isAuthenticated ? (<Estructura><Paquetes/></Estructura>) : (<Navigate to={{ pathname: '/login'}}/>)} />
        <Route  exact path={`metodos-pago`}  element={ isAuthenticated ? (<Estructura><MetodosPago/></Estructura>) : (<Navigate to={{ pathname: '/login'}}/>)}  /> 
        <Route  exact path={`comprobantes`}  element={ isAuthenticated ? (<Estructura><Comprobantes/></Estructura>) : (<Navigate to={{ pathname: '/login'}}/>)} />

      </Routes >
    </Suspense>
  )
}

export default React.memo(Micuenta);
