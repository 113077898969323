import {Row,Col} from 'antd'

import '../../css/ServiciosCard.css'

import {ArrowRightOutlined} from '@ant-design/icons'

import { Link } from 'react-router-dom';



function ServiciosCard({dataServicios}) {
  return (
    <div className="ServiciosCardComponent">
     <Row gutter={[32, 32]} gap={24} className='rowServiciosCard'>
        {
            dataServicios.map((data, index) => {
                return (
                    <Col key={index} xs={{span: 24}} sm={{span: 12}} md={{span: 12}} lg={{span:6}} >
                        <div className="itemServicioCard">
                        <img src={data.svg} alt="" />
                        <h4 className='titulo'>{data.titulo}</h4>
                        <p>{data.contenido}</p>
                        <div className="botonSecundario botonVermas"> <Link to={data.enlace}> VER MÁS <ArrowRightOutlined/></Link> </div>
                        </div>
                        
                    </Col>
                )
            })
        }
        
     </Row>
    </div>

    
  );
}

export default ServiciosCard;
