import {Row,Col} from 'antd'

import '../../css/Hero.css'


function Hero({datosShow}) {
  return (
    <div className="heroComponent">
     <Row className='rowHero'>
        <Col className='cont_text_hero' xs={{span: 24,}} md={{span: 12}}>
            <h1>{datosShow.titulo}</h1>
            <p>{datosShow.contenido}</p>
        </Col>
        <Col className='img_hero' xs={{span: 24}} md={{span: 12}}>
            <img src={datosShow.img} alt="" />
        </Col>
     </Row>
    </div>

    
  );
}

export default Hero;
