import fetch from './fetchInterceptor'

let prefix = `/`

const cuenta = {}

cuenta.getData = function (data) {
  return fetch({
    url: '/usuario/datosCuenta',
    method: 'post',
    data: data
  })
}

cuenta.ActualizarCuenta = function (data) {
  return fetch({
    url: '/usuario/actualizar',
    method: 'post',
    data: data
  })
}

cuenta.misPaquetes = function (token) {
  return fetch({
    url: '/usuario/consultarPaquetes',
    method: 'post',
    data: token
  })
}

cuenta.getComprobantes = function (token) {
  return fetch({
    url: '/usuario/comprobantes',
    method: 'post',
    data: token
  })
}

cuenta.Registrar = function (token) {
  return fetch({
    url: '/usuario/insertar',
    method: 'post',
    data: token
  })
}

cuenta.CambiarPassword = function (data) {
  return fetch({
    url: '/usuario/recuperarPasswordInterno',
    method: 'post',
    data: data
  })
}

cuenta.buscarPaquete = function (data) {
  return fetch({
    url: '/usuario/consultarPaquetesTracking',
    method: 'post',
    data: data
  })
}



cuenta.verificarEmail = function (data) {
  return fetch({
    url: '/usuario/verificarEmail',
    method: 'post',
    data: data
  })
}

cuenta.verificarCelular = function (data) {
  return fetch({
    url: '/usuario/verificarCelular',
    method: 'post',
    data: data
  })
}


cuenta.verificarCedula = function (data) {
  return fetch({
    url: '/usuario/verificarCedula',
    method: 'post',
    data: data
  })
}

cuenta.contactar = function (data) {
  return fetch({
    url: '/usuario/contactar',
    method: 'post',
    data: data
  })
}

export default cuenta