import './../../css/Servicios.css';

//TEMPLATE
import Header from '../header'
import LoginRegister from '../LoginRegister'
import Hero from '../componentes/Hero';
import CtaDudas from './../componentes/CtaDudas'


import nuestros_servicios from './../../assets/img/nuestros_servicios.png'
import {Row,Col} from 'antd'

import serv1 from './../../assets/img/serv1.png'
import serv2 from './../../assets/img/serv2.png'
import serv3 from './../../assets/img/serv3.png'
import serv4 from './../../assets/img/serv4.png'

import check from './../../assets/img/check.svg'

function NuestrosServicios() {
  let datosShow = {
    titulo : "NUESTROS SERVICIOS",
    contenido:"Brindamos un servicio de courier internacional flexible, eficiente y personalizado, comprometido con los objetivos de cada cliente. Comprá en internet y nosotros nos encargamos de que las distancias no se conviertan en un riesgo o un problema para vos. ",
    img:nuestros_servicios
  }

  
  return (
    <div className="App">
      <div className='contenedor'>
      <Header/>
      <Hero datosShow={datosShow}></Hero>
      <section id='serviciosFullCards'>
        <div className="itemServ">
          <Row className='rowItemServ'>
            <Col xs={{span: 24}} md={{span: 12}}> 
              <div className="linea-flex">
              <img src={check} alt="" />
              <h3 className='titulo_degradado'>TRANSPORTE AÉREO</h3>
              </div>
              <p>Nos dedicamos al transporte aéreo de todo tipo de carga. Nosotros nos encargamos de todos los trámites adicionales para que tu paquete llegue en tiempo y forma hasta vos.</p>
            </Col>


            <Col xs={{span: 24}} md={{span: 12}}>
              <img src={serv1} alt="AEREO" />
            </Col>
          
          </Row>
        </div>

        <div className="itemServ">
          <Row className='rowItemServ '>
            <Col className='order1' xs={{span: 24}} md={{span: 12}}> 
              <div className="linea-flex">
              <img src={check} alt="" />
              <h3 className='titulo_degradado'>COMPRAMOS POR VOS</h3>
              </div>
              <p>¿No tenés tarjeta de crédito? Nosotros compramos por vos y lo recibís en la puerta de tu casa u oficina. Solo tenés que indicarnos el producto que querés y nosotros nos encargamos de la compra y los trámites de aduana.</p>
            </Col>


            <Col xs={{span: 24}} md={{span: 12}}>
              <img src={serv2} alt="AEREO" />
            </Col>
          
          </Row>
        </div>


        <div className="itemServ">
          <Row className='rowItemServ'>
            <Col xs={{span: 24}} md={{span: 12}}> 
              <div className="linea-flex">
              <img src={check} alt="" />
              <h3 className='titulo_degradado'>TRANSPORTE MULTIMODAL</h3>
              </div>
              <p>Tu paquete llega hasta vos totalmente seguro y con tiempos de entrega que respetan los plazos establecidos con anterioridad.</p>
            </Col>


            <Col xs={{span: 24}} md={{span: 12}}>
              <img src={serv4} alt="AEREO" />
            </Col>
          
          </Row>
        </div>

        {/* <div className="itemServ">
          <Row className='rowItemServ '>
            <Col className='order1' xs={{span: 24}} md={{span: 12}}> 
              <div className="linea-flex">
              <img src={check} alt="" />
              <h3 className='titulo_degradado'>TRANSPORTE AÉREO</h3>
              </div>
              <p>Nos dedicamos al transporte aéreo de todo tipo de carga, desde cualquier parte del mundo. Nuestra especialidad: Servicio puerta a puerta desde Estados Unidos bajo la modalidad all in, donde nosotros nos encargamos de todos los trámites adicionales.</p>
            </Col>


            <Col xs={{span: 24}} md={{span: 12}}>
              <img src={serv4} alt="AEREO" />
            </Col>
          
          </Row>
        </div> */}

        
      </section>
      <CtaDudas/>

      </div>
    </div>

    
  );
}

export default NuestrosServicios;
