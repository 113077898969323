import React,{useMemo, useState} from 'react';
import { Button, Checkbox, Form, Input, Row,Col, Select, DatePicker, notification, Spin } from 'antd';
import { useNavigate} from 'react-router-dom';

import {LockOutlined, EditOutlined,CheckOutlined,LoadingOutlined }  from '@ant-design/icons'
import Header from './header'

import { Link } from 'react-router-dom';

import GoogleMaps from './componentes/GoogleMaps';
import Autocomplete from "react-google-autocomplete";

/*SERVICIOS*/

import auth from '../services/authService';
import cuenta from '../services/cuentaService';

const RegisterForm = () => {
    const { Option } = Select;
    const [form] = Form.useForm();

    const navigate = useNavigate();
    const [componentDisabled, setComponentDisabled] = useState(true);
    const [lat, setLat] = useState(null);
    const [long, setLong] = useState(null);
    const [Direccion, setDireccion] = useState(null);

    const [enviando, setEnviando] = useState(null);

    const onFinish = async (values) => {
      // values.token = localStorage.getItem('token')
      if(enviando == null){
        setEnviando(true)
        values.latitud = lat;
        values.longitud = long;
        values.direccion = Direccion;
          let login = await cuenta.Registrar(values)
          setEnviando(false)
          console.log(login)
          localStorage.setItem('token',login)
          if(login){
          // navigate('/mi-cuenta');
          // window.location
          window.location.href = '/mi-cuenta';
          }
          // console.log('Success:', values);
      }

    };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(!componentDisabled);
  };

  const verificarDocumento = async (e)=>{
    console.log(e.target.value)
    let servicioVerificar =await cuenta.verificarCedula({num_documento:e.target.value})
    console.log(servicioVerificar.encontro )
    if(servicioVerificar.encontro  == true){
      notification.error({
        message: 'Número de documento ya se encuentra registrado'
      })
    }
  }

  const verificarCelular = async (e)=>{
    console.log(e.target.value)
    let servicioVerificar =await cuenta.verificarCelular({telefonoMovil:e.target.value})
    console.log(servicioVerificar.encontro )
    if(servicioVerificar.encontro  == true){
      notification.error({
        message: 'Número de celular ya se encuentra registrado'
      })
    }
  }

  const verificarEmail = async (e)=>{
    console.log(e.target.value)
    let servicioVerificar =await cuenta.verificarEmail({email:e.target.value})
    console.log(servicioVerificar.encontro )
    if(servicioVerificar.encontro  == true){
      notification.error({
        message: 'Correo ya se encuentra registrado'
      })
    }
  }

  let maparender = useMemo(()=>{
    console.log('aaaaaa relg')
    return <GoogleMaps 
    latitude={lat}
    longitude={long}
    />
  },[lat])

  const cambiarLatLongManual = async (lats,longs) =>{
    console.log("cambido")
    setLat(lats)
    setLong(longs)
  }

  return (
    <>
    <div className="App">
      <div className='contenedor' id='pageRegistrar'>
      <Header/>
      {/* {maparender} */}
      
      <Form
    form={form}

      id={"formDatosUser"}
      layout={"vertical"}
      name="basic"
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
    <Row style={{width:"100%"}} gutter={[16, 16]}>
      <Col span={12}>
      <Form.Item
        label="Nombre Completo"
        name="nombre"
        rules={[
          {
            required: true,
            message: 'Ingresa tu nombre',
          },
        ]}
      >
        <Input />
      </Form.Item>
      </Col>
      
      <Col span={12}>
      <Form.Item
        label="Tipo de Documento"
        name="tipoDocumento"
        rules={[
          {
            required: true,
            message: 'Selecciona tipo de documento',
          },
        ]}
      >
        <Select>
            <Option value="1">Cedula de identidad </Option>
            <Option value="2">R.U.C. </Option>
            <Option value="3">Documento extranjero</Option>
               
        </Select>
      </Form.Item>
      </Col>
      <Col span={12}>
      <Form.Item
        label="Número Documento"
        name="num_documento"

        rules={[
          {
            required: true,
            message: 'Ingresa número documento',
          },
        ]}
      >
        <Input onBlur={verificarDocumento} />
      </Form.Item>
      </Col>
      <Col span={12}>
      <Form.Item
        label="Correo Electrónico"
        name="email"
        rules={[
          {
            required: true,
            message: 'Ingresa un correo válido',
          },
        ]}
      >
        <Input style={{width:'100%'}} onBlur={verificarEmail} />
      </Form.Item>
      </Col>

      <Col span={12}>
      <Form.Item
        label="Teléfono"
        name="telefonoMovil"
        rules={[
          {
            required: true,
            message: 'Ingresa un número de teléfono',
          },
        ]}
      >
        <Input onBlur={verificarCelular} />
      </Form.Item>
      </Col>

      <Col span={12}>
      <Form.Item
        label="Fecha de Nacimiento"
        name="fechaNacimiento"
        rules={[
          {
            required: true,
            message: 'Ingresa tu fecha de nacimiento',
          },
        ]}
      >
               <DatePicker style={{width:"100%"}} />

      </Form.Item>
      </Col>

      <Col span={12}>
      <Form.Item
        label="Sexo"
        name="sexo"
        rules={[
          {
            required: true,
            message: 'Selecciona una opción',
          },
        ]}
      >
       
       <Select>
            <Option value="1">Másculino </Option>
            <Option value="2">Femenino</Option>
               
        </Select>
      </Form.Item>
      </Col>

      <Col span={12}>
      <Form.Item
        label="Contraseña"
        name="clave"
        
      >
       <Input type='password' />
      </Form.Item>
      <Autocomplete
      className='input-search-direccion'
        apiKey={'AIzaSyBVbCmKkfBfmcsC5zjkk70WttiFIcxdWHI'}
        onPlaceSelected={(place) => {
          console.log(place);
          console.log(place.formatted_address)

          console.log('Lat', place.geometry.location.lat())
          console.log('Lng', place.geometry.location.lng())
          setLong( place.geometry.location.lng())
          setLat(place.geometry.location.lat())
          setDireccion(place.formatted_address)
  
        }}
        options={{
          types: ["address"],
          componentRestrictions: { country: "py" },
        }}
      />
      <GoogleMaps 
      latitude={lat}
      longitude={long}
      cambiarLatLongManual={cambiarLatLongManual}
      />
      

      </Col>    

      
      <div className="cont_btns">
            <div className="item_Tbn">
                <Button type={"submit"} htmlType={'submit'} className={"botonPrimario"} >

                  {
                    enviando ?   <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : <CheckOutlined />
                  }
                     REGISTRAR
                </Button>
            </div>

            <div className="item_Tbn">
                   <Link  className={"botonSecundario"} to={"/login"}> <LockOutlined/> INICIAR SESIÓN</Link>
            </div>
      </div>
    </Row>
    
      
    </Form>
    
      </div>
    </div>

    

    </>

    

    

    
  );
};
export default RegisterForm;