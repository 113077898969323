import './../../css/Home.css';

//TEMPLATE
import Header from '../header'
import Footer from '../footer';

import LoginRegister from '../LoginRegister'
import Hero from '../componentes/Hero';
import ServiciosCards from '../componentes/ServiciosCards'
import CarouselMarcas from '../componentes/CarouselMarcas';
import CtaDudas from '../componentes/CtaDudas';


import homeimg1 from './../../assets/img/homeimg1.png'
import homeimg2 from './../../assets/img/homeimg2.png'
import homeimg3 from './../../assets/img/homeimg3.png'


import {Input,Row,Col, Carousel,Modal} from 'antd'

import Compras from '../../assets/img/compras.svg'
import Camion from '../../assets/img/camion.svg'
import Avion from '../../assets/img/avion.svg'
import Ancla from '../../assets/img/ancla.svg'

import Amazon from '../../assets/img/amazon.png'
import Ebay from '../../assets/img/ebay.png'
import Levis from '../../assets/img/levis.png'
import Walmart from '../../assets/img/walmart.png'

import serv2 from './../../assets/img/serv2.png'
import check from './../../assets/img/check.svg'


import home1 from './../../assets/img/home1.png'
import home2 from './../../assets/img/home2.png'
import home3 from './../../assets/img/home3.png'

import { useState } from 'react';
import cuenta from '../../services/cuentaService';

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [numtrack, setNumTrack] = useState(false);
  const [dataSearch, setDataSearch] = useState(false);
  const [msgIniciar, setMsgIniciar] = useState(false);

  let datosShow = {
    titulo : "Asesoría sin cargo con atención personalizada",
    contenido:"Somos una empresa orientada al servicio del cliente y apostamos en mejorar continuamente tu experiencia utilizando nuestros servicios.",
    img:homeimg1
  }
  let datosShow2 = {
    titulo : "traemos tus paquetes desde $21.50 el kilo",
    contenido:"¡No pagues de más! Contamos con una de las tarifas más bajas del mercado. Incluye impuestos y gestiones de aduanas.",
    img:homeimg2
  }
  let datosShow3 = {
    titulo : "TARIFA ESPECIAL PARA EMPRENDEDORES",
    contenido:"¿Tenés un emprendimiento? Te ofrecemos una tarifa especial, eficiente y personalizada para ayudarte a cumplir con tus objetivos.",
    img:homeimg3
  }

  let dataServicios=[
    {
      titulo:"COMPRÁ SIN TARJETA",
      contenido:"Pedinos lo que querés traer y nosotros lo compramos por vos.",
      svg:Compras,
      enlace:"/servicios"
    },
    {
      titulo:"SERVICIO MARÍTIMO",
      contenido: "Solicitá un presupuesto, enviando un correo a atc@idealbox.com.py",
      svg:Ancla,
      enlace:"/servicios"
    },
    {
      titulo:"SERVICIO DE FLETE AÉREO",
      contenido:"Garantizamos agilidad y seguridad para todos tus paquetes.",
      svg:Avion,
      enlace:"/servicios"
    },
    {
      titulo:"pago online y delivery",
      contenido:"Pagá online y agendá la entrega del paquete de forma rápida y segura.",
      svg:Camion,
      enlace:"/servicios"
    }
    
  ]


  const showModal = async() => {
    let token = localStorage.getItem('token')
    if(token){
      let buscar = await cuenta.buscarPaquete({token,nroTracking:numtrack})
      console.log(buscar.paquetes[0])
      setDataSearch(buscar.paquetes[0])
      console.log("ABRIR MODAL")
    }
    else{
      setMsgIniciar('Debes iniciar sesión para buscar rastraer un paquete')
    }
    
    setIsModalOpen(true);
  };
  const handleOk =  async () => {
   

    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="App">
      <Modal className='modal-idealbox' title="Consulta Paquete" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
       {dataSearch  ? <ul>
          <li><strong>Descripcion: </strong>{dataSearch.descripcion}</li>
          <li><strong>Estado: </strong>{dataSearch.estado}</li>
          <li><strong>Fecha Entrega: </strong>{dataSearch.fechaEntrega}</li>
          <li><strong>idFactura: </strong>{dataSearch.idFactura}</li>
          <li><strong>Num Tracking: </strong>{dataSearch.nroTracking}</li>
          <li><strong>Origen: </strong>{dataSearch.origen}</li>
          <li><strong>Perso Real: </strong>{dataSearch.pesoReal}</li>
        </ul> : !msgIniciar &&
          <p style={{color:'white'}}>No se encontraron resultados</p> }

       
          <p>{msgIniciar}</p>
        
      </Modal>
      <div className='contenedor'>
      <Header/>
      <Carousel autoplay>
      <Hero datosShow={datosShow}></Hero>
      <Hero datosShow={datosShow2}></Hero>
      <Hero datosShow={datosShow3}></Hero>
     </Carousel>
      
      <section id='rastrearPaquete'>
        
        <h3 className='titulo_degradado'>RASTREA TU PAQUETE</h3>
        <div className="busqueda_bar">
        <div className="txt_info">
          <p>Ingresá el número de tracking</p>
          <div className="arrow_amarillo"></div>
        </div>
          <Input onChange={(e)=>setNumTrack(e.target.value)}></Input>
          <div className="botonPrimario btn_buscar" onClick={showModal}> <p>Buscar</p> </div>
        </div>
      </section>

      <ServiciosCards dataServicios={dataServicios} />
      {/* <CarouselMarcas/> */}
      <section id='marcas'>
        <h3 className='titulo_degradado'>comprá en las mejores tiendas</h3>
        <div className="cont_marcas">
          <div className="item_marcas">
            <img src={Amazon} alt="" />
          </div>
          <div className="item_marcas">
            <img src={Ebay} alt="" />
          </div>
          <div className="item_marcas">
            <img src={Levis} alt="" />
          </div>
          <div className="item_marcas">
            <img src={Walmart} alt="" />
          </div>  
        </div>
      </section>


      {/* <section id='destacado_home_bajo'>
        <div className="itemServ destacadoHome">
          <Row className='rowItemServ '>
            <Col className='' xs={{span: 24}} md={{span: 12}}> 
              <div className="linea-flex">
              <img src={check} alt="" />
              <h3 className='titulo_degradado'>?no tenés tarjeta?</h3>
              </div>
              <p>No te preocupes, ¡nosotros compramos por vos.</p>
            </Col>


            <Col xs={{span: 24}} md={{span: 12}}>
              <img src={home1} alt="tarjeta" />
            </Col>
          
          </Row>
        </div>
        <div className="itemServ destacadoHome">
          <Row className='rowItemServ '>
            <Col className='order1' xs={{span: 24}} md={{span: 12}}> 
              <div className="linea-flex">
              <img src={check} alt="" />
              <h3 className='titulo_degradado'>?te falta un respuesto?</h3>
              </div>
              <p>Llená el formulario con los datos requeridos y nosotros buscamos por vos.</p>
            </Col>


            <Col xs={{span: 24}} md={{span: 12}}>
              <img src={home2} alt="tarjeta" />
            </Col>
          
          </Row>
        </div>
        <div className="itemServ destacadoHome">
          <Row className='rowItemServ '>
            <Col className='' xs={{span: 24}} md={{span: 12}}> 
              <div className="linea-flex">
              <img src={check} alt="" />
              <h3 className='titulo_degradado'>?buscas un auto?</h3>
              </div>
              <p>Solicitá el presupuesto del auto que quieras y te lo traemos.</p>
            </Col>


            <Col xs={{span: 24}} md={{span: 12}}>
              <img src={home3} alt="tarjeta" />
            </Col>
          
          </Row>
        </div>
      </section> */}
      <CtaDudas/>
      </div>
      {/* <Footer/> */}
    </div>
    

    
  );
}

export default App;
