import './../../css/Somos.css';

//TEMPLATE
import Header from '../header'
import LoginRegister from '../LoginRegister'
import Hero from '../componentes/Hero';
import CtaDudas from './../componentes/CtaDudas'

import quienes_somos from './../../assets/img/quienessomos.png'

import {Row,Col} from 'antd'


import Vision from './../../assets/img/vision.svg'
import Mision from './../../assets/img/mision.svg'
import Valores from './../../assets/img/valores.svg'


import Rayito from './../../assets/img/rayito.svg'
import Box from './../../assets/img/box.svg'
import Like from './../../assets/img/like.svg'
import Diamante from './../../assets/img/diamante.svg'


import ValoresNosotros from '../componentes/Valores';
function QuienesSomos() {
  let datosShow = {
    titulo : "¿QUIÉNES SOMOS?",
    contenido:"Somos una empresa Paraguaya de servicios de transporte internacional. Ofrecemos un servicio puerta a puerta para que puedas comprar en internet los 365 días del año. En IDEALBOX hacemos nuestro mejor esfuerzo para brindarte una atención personalizada para garantizar que tus compras lleguen a tiempo y de forma segura.",
    img:quienes_somos
  }
  let dataServicios=[
    {
      titulo:"VISIÓN",
      contenido:"Ser la empresa de servicios logísticos y courier internacional preferida del mercado paraguayo por nuestra calidez humana, satisfacción al cliente y vocación de servicio.",
      svg:Vision,
      enlace:"#"
    },
    {
      titulo:"MISIÓN",
      contenido: "Ofrecer satisfacción a nuestros clientes teniendo como prioridad un trabajo responsable y de calidad donde el cliente está en primer lugar, asegurando la seguridad y eficiencia en el trasporte de cargas. ",
      svg:Mision,
      enlace:"#"
    },
    {
      titulo:"VALORES",
      contenido:"La integridad y el compromiso de nuestro equipo son los pilares básicos de nuestra organización. Nos enfocamos en la innovación, la atención personalizada, la flexibilidad y las mejoras continuas.",
      svg:Valores,
      enlace:"#"
    },
    
    
  ]
  return (
    <div className="App">
      <div className='contenedor'>
      <Header/>
      <Hero datosShow={datosShow}></Hero>
      <ValoresNosotros dataServicios={dataServicios} />

      <div id="somosEmpresa">
        <Row gutter={[32, 32]}>
          <h2 className='titulo_degradado'>SOMOS UNA EMPRESA QUE SE DESTACA</h2>
          <Col xs={{span: 24}} md={{span: 12}}>
            <div className="itemSomos">
              <div className="svgA">
                <img src={Rayito} alt="" />
              </div>
              <div className="subTxt">
              <h3>SOMOS RÁPIDOS</h3>
              <p>Sabemos el valor del tiempo y es por ello que nos enfocamos en dar soluciones rápidas. Contamos con dos vuelos semanales para agilizar el de envío de los paquetes.</p>
              </div>
            </div>
          </Col>

          <Col xs={{span: 24}} md={{span: 12}}>
            <div className="itemSomos">
              <div className="svgA">
                <img src={Like} alt="" />
              </div>
              <div className="subTxt">
              <h3>SOMOS CONFIABLES Y SEGUROS</h3>
              <p>IDEALBOX trabaja con ética y responsabilidad empresarial, para asegurar que cada carga transportada llegue en las condiciones adecuadas desde nuestros depósitos hasta tus manos.</p>
              </div>
            </div>
          </Col>


          <Col xs={{span: 24}} md={{span: 12}}>
            <div className="itemSomos">
              <div className="svgA">
                <img src={Box} alt="" />
              </div>
              <div className="subTxt">
              <h3>SOMOS INNOVADORES</h3>
              <p>Nos caracterizamos por estar siempre a la vanguardia en las innovaciones en sus diferentes ámbitos; tecnología, promociones, servicios y atención al cliente.</p>
              </div>
            </div>
          </Col>


          <Col xs={{span: 24}} md={{span: 12}}>
            <div className="itemSomos">
              <div className="svgA">
                <img src={Diamante} alt="" />
              </div>
              <div className="subTxt">
              <h3>SOMOS LO QUE ESPERÁS</h3>
              <p>Hacemos nuestro mejor esfuerzo para que vivas la mejor experiencia de compra en línea. Apostamos en contar con los mejores recursos y la mejor tecnología..</p>
              </div>
            </div>
          </Col>

          
        </Row>
      </div>
      <CtaDudas/>
      </div>
    </div>

    
  );
}

export default QuienesSomos;
