import logo from './../assets/img/logo.png';
import {Menu} from 'antd'
import { UserOutlined, DeleteOutlined } from '@ant-design/icons'
import { Link, useNavigate } from "react-router-dom";
import EllipsisDropdown from './shared/ElipsisDrop'
const ItemAction = ({id, CerrarSesion}) => (
	<EllipsisDropdown 
    islogin={true}
		menu={
			<Menu>
				<Menu.Item key="0">
          <Link to={"/mi-cuenta"}>
          <UserOutlined />
					<span>Mi Cuenta</span>
          </Link>
				</Menu.Item>
        <Menu.Item key="1" onClick={CerrarSesion}>
				  <DeleteOutlined />
					<span>Cerrar Sesión</span>
				</Menu.Item>
				
				<Menu.Divider />
				
			</Menu>
		}
	/>
)



function App() {
  const navigate = useNavigate();

  const CerrarSesion = () =>{
    console.log("logout")
    // navigate('/')
    window.location.href = 'http://idealbox.com.py/';
    localStorage.clear()
    
  }
  const jwtoken = localStorage.getItem("token");
  return (
    
      <header className="headerPage">
        <div className="contenedor_logo">
          <Link to={"/"}><img src={logo}  alt="logo" /></Link>
        
        </div>
        
        <nav id="menuprincipal">
        <ul>
            <li><Link to="/">INICIO</Link></li>
            <li><Link to="/quienes-somos">NOSOTROS</Link></li>
            <li><Link to="/servicios">SERVICIOS</Link></li>
            <li><Link to="/como-comprar">CÓMO COMPRAR</Link></li>
            <li><Link to="/contacto">CONTACTO</Link></li>
        </ul>
        </nav>
        {
          !jwtoken &&  <div >
          <Link to={"/login"} className='btn_register'><UserOutlined />
          <p>Login</p></Link>
          </div>
        }

        {
          jwtoken && <div className='btn_micuenta'><Link to={"/mi-cuenta"}><UserOutlined /> </Link> <ItemAction CerrarSesion={CerrarSesion} className="btn_micuenta" islogin={true} /></div>
        }
        

      </header>
    
  );
}

export default App;
