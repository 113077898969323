import axios from 'axios'
import history from '../history.js'
import { notification } from 'antd';

const service = axios.create({
  baseURL: `https://idealbox.com.py/idealbox/api/public`,
//   baseURL:`https://jsonplaceholder.typicode.com/`,
  timeout: 60000
})

// Config
const ENTRY_ROUTE = '/login'
const TOKEN_PAYLOAD_KEY = 'access_token'
const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem("token")
	
  if (jwtToken) {
    config.headers[TOKEN_PAYLOAD_KEY] = jwtToken
  }

  if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
		history.push(ENTRY_ROUTE)
		// window.location.reload();
  }

  return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
  Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	let notificationParam = {
		message: response.data.message
	}
	// if(response.data.status === false){
	// 	notificationParam.message = 'ERROR AL REALIZAR ACCIÓN';
	// 	notificationParam.description = response.data.message;
	// 	notification.error(notificationParam);

	// }
	// if(response.data.status === true){
	// 	notificationParam.message = response.data.message;
	// 	notificationParam.description = 'Éxito';
	// 	notification.success(notificationParam);

	// }
	return response.data
}, (error) => {

	let notificationParam = {
		message: ''
	}

	// if(error.response.status === 400 || error.response.status === 403 ){
	// 	notificationParam.message =  error.response.data.message;
	// 	notificationParam.description =  error.response.data.error;
	// }


	
	// // Remove token and redirect 
	// if (error.response.status === 401) {
	// 	notificationParam.message = 'Sesión expirada'
	// 	notificationParam.description = 'Por favor ingresa de nuevo'
	// 	localStorage.removeItem("token")
	// 	history.push(ENTRY_ROUTE)
	// 	window.location.reload();
	// }

	// if (error.response.status === 404) {
	// 	notificationParam.message = 'Not Found'
	// }

	// if (error.response.status === 500) {
	// 	notificationParam.message = 'Internal Server Error'
	// }
	
	// if (error.response.status === 508) {
	// 	notificationParam.message = 'Time Out'
	// }

	// notification.error(notificationParam)

	return Promise.reject(error);
});

export default service