import fetch from './fetchInterceptor'

let prefix = `/auth/`

const auth = {}

auth.Login = function (email,clave) {
  return fetch({
    url: `${prefix}autenticar`,
    method: 'post',
    headers: {
		'public-request': 'true',
		// 'credentials': 'include',
	},
    data:{email,clave}
  })
}

auth.setPost = function (data) {
  return fetch({
    url: '/posts',
    method: 'post',
    data: data
  })
}

export default auth