import logo from './../assets/img/logo.png';
import LoginForm from './LoginForm'
import { FieldTimeOutlined,CalendarOutlined,SearchOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
function LoginRegister() {
  return (
    
      <section id='login_register'>
         <div id='login_cuadro'>
                <h2>INICIAR SESIÓN</h2>
                <LoginForm/>
          </div>
          
        <div className='line_separador'></div>
        <div id='login_cuadro'>
                <h2>CREAR CUENTA</h2>
                <div className="beneficios">
                  <h3>BENEFICIOS</h3>
                  <ul>
                    <li><FieldTimeOutlined /> Finalizar tus pedidos más rapido.</li>
                    <li><CalendarOutlined /> Consultar tu historial de compras.</li>
                    <li><SearchOutlined /> Rastrear tus pedidos.</li>
                  </ul>
                </div>

                <div className='btn_outline'>
                  <Link to={"/register"}><p>REGISTRATE</p></Link>
                </div>
          </div>
      </section>
    
  );
}

export default LoginRegister;
