import './../../css/Micuenta.css';

//TEMPLATE
import Header from '../header'
import Menulateral from './menuLatera'
import { CodeSandboxOutlined, UserOutlined, FileTextOutlined, CreditCardOutlined } from '@ant-design/icons'


function Estructura({children}) {
  return (
    
    <div className="App">
      <div className='contenedor'>
      <Header/>
      
      <div className="wrap_cuenta">
        <Menulateral/>
        {children}
      </div>

      </div>
    </div>
    
  );
}

export default Estructura;
