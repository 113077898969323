import './../css/Contacto.css';

//TEMPLATE
import Header from '../header'
import LoginRegister from '../LoginRegister'

import CtaDudas from './../componentes/CtaDudas'

import FormularioContacto from '../componentes/FormularioContacto';
import MapSection from '../componentes/Mapa'
const location = {
  address: '1600 Amphitheatre Parkway, Mountain View, california.',
  lat: 37.42216,
  lng: -122.08427,
} // our location object from earlier
function ContactoPage() {


  return (
    <div className="App">
      <div className='contenedor'>

      <Header/>
        <section id='mapaContacto'>
        </section>

        <section id='formContacto'>
            <div className="datosBox">
                <div className="datosTxt">
                    {/* <div className="itemDatoCo">
                        <p className='grisText'>DIRECCIÓN</p>
                        <span className='data_W'>Calle Maria Auxiliadora Nro.: 722 entre San Martin y San Marcos</span>
                    </div> */}
                    <div className="itemDatoCo">
                        <p className='grisText'>CORREO ELECTRÓNICO</p>
                        <a href="mailto:atc@idealbox.com.py" target="_blank"> <span className='data_W'>atc@idealbox.com.py</span></a>
                    </div>
                    <div className="itemDatoCo">
                        <p className='grisText'>TÉLEFONO</p>
                        <a href='https://wa.me/qr/5W26KZZKM2WFE1' target="_blank"><span className='data_W'>(0982) 912 331</span></a>
                    </div>
                    <div className="itemDatoCo">
                        <p className='grisText'>WHATSAPP</p>
                        <a href='https://wa.me/qr/5W26KZZKM2WFE1' target="_blank"><span className='data_W'>(0982) 912 331</span></a>
                    </div>
                    <div className="itemDatoCo">
                        <p className='grisText'>ATENCIÓN</p>
                        <span className='data_W'> <p>LUNES A VIERNES  8:30 – 18:00 <br/> SÁBADOS 9:00 – 12:00</p> </span>
                    </div>
                    
                </div>
            </div>
            <div className="formContact">
                <FormularioContacto/>
            </div>
        </section>
        


        {/* <CtaDudas/> */}
      </div>
    </div>

    
  );
}

export default ContactoPage;
