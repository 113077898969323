import React,{useState} from 'react';
import { Button, Checkbox, Form, Input, Select,notification, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {DeleteOutlined,CheckOutlined,LoadingOutlined}  from '@ant-design/icons'
import cuenta from '../../services/cuentaService';

const FormularioContacto = () => {
    const [form] = Form.useForm();
    const [enviando, setEnviando] = useState(null);

    let {Option} = Select;
  const onFinish = async(values) => {
    if(enviando == null){
      setEnviando(true)
      console.log('Success:', values);
      let contactar = await cuenta.contactar(values) 
      notification.success({message:'Mensaje enviado, nos contactaremos contigo', description: 'Éxito'});
      setEnviando(false)
    }

  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  return (
    <Form
    form={form}
      id='formularioContacto'
      layout='vertical'
      name="basic"
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Nombre y Apellido"
        name="nombre"
        rules={[
          {
            required: true,
            message: 'Ingresa tu nombre!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Teléfono"
        name="telefono"
        rules={[
          {
            required: true,
            message: 'Ingresa un teléfono para contactarnos contigo',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Motivo"
        name="motivo"
        rules={[
          {
            required: true,
            message: 'Selecciona el motivo',
          },
        ]}
      >
        <Select>
            <Option value="1">Contacto </Option>
            <Option value="2">Dudas</Option>
            <Option value="3">Información</Option>
               
        </Select>
      </Form.Item>

      <Form.Item
        label="Mensaje"
        name="mensaje"
        rules={[
          {
            required: true,
            message: 'Ingresa un mensaje!',
          },
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>
      

      <div className="cont_btns">
            <div className="item_Tbn">
                <Button type={"submit"} htmlType="submit" className={"botonPrimario"} >
                  {
                    enviando ?   <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : <CheckOutlined />
                  } ENVIAR
                </Button>
            </div>

            <div className="item_Tbn">
                <Button onClick={(e) => {  form.resetFields();}}  className={"botonSecundario"} >
                <DeleteOutlined/> BORRAR CAMPOS
                </Button>
                
                   
                
            </div>
      </div>
    </Form>
  );
};
export default FormularioContacto;