import React from 'react';
import { Collapse } from 'antd';
const { Panel } = Collapse;

const PreguntasItem = ({dataPreguntas}) => (
  <div id='faq' className='collapse_preguntasfaq'>
    <h3 className="titulo_degradado">PREGUNTAS FRECUENTES</h3>
    <Collapse bordered={false} defaultActiveKey={['1']}>
    {
        dataPreguntas && dataPreguntas.map((data, index) =>{
            return (
            <Panel header={data.pregunta} key={index}>
                {data.respuesta}
            </Panel>
            )
        })
    }
    

    
  </Collapse>
  </div>
);
export default PreguntasItem;