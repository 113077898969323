import React from 'react';
import { Button, Checkbox, Form, Input, notification } from 'antd';
import { useNavigate} from 'react-router-dom';



/*SERVICIOS*/

import auth from '../services/authService';

const LoginForm = () => {
    const navigate = useNavigate();

  const onFinish = async (values) => {
    let login = await auth.Login(values.email, values.clave)
    console.log(login)
    if(login.result !== null){
      localStorage.setItem('token',login.result)
      console.log("redirigir")
      // navigate('/mi-cuenta');
      window.location.href = '/mi-cuenta';
    }
    else{
      notification.error({
        message: 'Credenciales no válidas'
      })
    }
    



    console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <Form
      id={"formLogin"}
      layout={"vertical"}
      name="basic"
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Correo electrónico"
        name="email"
        rules={[
          {
            required: true,
            message: 'Ingresa tu email',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Contraseña"
        name="clave"
        rules={[
          {
            required: true,
            message: 'Introduce tu contraseña!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      

      <p className='link_recuperar_pass'> <a href="">¿Olvidaste tu contraseña?</a> </p>

      <Form.Item
        
      >
        <Button className='botoniniciar' type="primary" htmlType="submit">
          INGRESAR
        </Button>
      </Form.Item>
    </Form>
  );
};
export default LoginForm;