import {Row,Col} from 'antd'


import {ArrowRightOutlined} from '@ant-design/icons'

import { Link } from 'react-router-dom';


function CtaDudas({dataServicios}) {
  return (
    <div className="cta_dudas">
     <Row className='row_cta_dudas'>
        <Col> <h3>TenÉs dudas o consultas sobre el servicio?</h3> </Col>
        <Col> 
          {/* <div className="txt_info"><p>Ingresá el número de tracking</p><div className="arrow_amarillo"></div></div> */}
            <div className="botonPrimario btn_contactar"><Link to={"contacto"}><p>CONTACTÁ CON NOSOTROS</p></Link> </div>
        </Col>
     </Row>
    </div>

    
  );
}

export default CtaDudas;
