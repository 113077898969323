import React, { Component, useMemo } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const MapaComponent = ({markers,google,lat,lng,onMarkerDragEnd})=>{
    console.log(lat)
    console.log(lng)
    if(lat){
        console.log("hola mapac lat ")
    }
    const Mapa = useMemo(()=>{
        console.log("se  ha cambiado lat"+lat)
        return(
        lat && lng? 
        <Map
            google={google}
            style={{
                width: "auto",
                height: "300px",
                position:'relative !important'
            }}
            zoom={14}
            initialCenter={{ lat: markers[0].position.lat, lng: markers[0].position.lng }}
            center={{ lat: markers[0].position.lat, lng: markers[0].position.lng }}
            >
            {markers.map((marker, index) => (
                <Marker
                    key={index}
                    position={marker.position}
                    draggable={true}
                    onDragend={(t, map, coord) => onMarkerDragEnd(coord, index)}
                    name={marker.name}
                />
            ))}
        </Map> : null
        )
    },[lat,lng,markers])
    return Mapa
}

class Maps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            markers: [
                {
                    name: "Current position",
                    position: {
                        lat: this.props.latitude,
                        lng: this.props.longitude
                    }
                }
            ]

        }
    }

    onMarkerDragEnd = (coord, index) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();

        console.log(lat)
        console.log(lng)

        this.setState(prevState => {
            const markers = [...this.state.markers];
            markers[index] = { ...markers[index], position: { lat, lng } };
            return { markers };
        });

        this.props.cambiarLatLongManual(lat,lng)

    }

    getLocation = () => {
        if (navigator && navigator.geolocation && !this.props.latitude) {
            navigator.geolocation.getCurrentPosition(pos => {
                const coords = pos.coords;

                let newState = Object.assign({}, this.state);
                newState.markers[0].position.lat = coords.latitude;
                newState.markers[0].position.lng = coords.longitude;

                // this.setState(newState);
                console.log(newState)

                this.setState(prevState => {
                    const markers = [...this.state.markers];
                    markers[0] = { ...markers[0], position: { lat: coords.latitude, lng: coords.longitude} };
                    console.log(markers)
                    return { markers };
                });


                this.props.cambiarLatLongManual(coords.latitude,coords.longitude)

                console.log("map", this.state.markers[0].position.lat, this.state.markers[0].position.lng)
            });
        }
        else if(this.props.latitude && this.props.longitude){
            this.setState(prevState => {
                const markers = [...this.state.markers];
                markers[0] = { ...markers[0], position: { lat:this.state.markers[0].position.lat, lng:this.state.markers[0].position.lng } };
                return { markers };
            });
        }
    }


    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.latitude !== this.state.markers[0].position.lat) {
          console.log("lattitud a cambiado")
          this.setState(prevState => {
            const markers = [...this.state.markers];
            markers[0] = { ...markers[0], position: { lat:nextProps.latitude, lng:nextProps.longitude } };
            return { markers };
            });
        }   
      }

      
    componentDidMount() {
        this.getLocation()
        
        
    }

    handleSubmit = async e => {
        e.preventDefault();

        const location = {
            latitude: this.state.markers[0].position.lat,
            longitude: this.state.markers[0].position.lng
        }
        console.log(location)

    }

    render() {
        console.log(this.props.latitude)
        console.log(this.props.longitude)
        return (

            <div
            style={{position:'relative'}}
            className={'id_vv_googlemaps'}
            >
                <MapaComponent
                lat={this.props.latitude}
                lng={this.props.longitude}
                markers={this.state.markers}
                google={this.props.google}
                onMarkerDragEnd={this.onMarkerDragEnd}
                />
                <button type="submit" onClick={this.handleSubmit} >submit</button>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBVbCmKkfBfmcsC5zjkk70WttiFIcxdWHI'
})(Maps);