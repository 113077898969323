import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import './css/App.css'

import Login from "./template/LoginPage";
import MiCuenta from "./template/micuenta/index";
import Home from "./template/Paginas/Home.js";
import NuestrosServicios from "./template/Paginas/NuestrosServicios";
import QuienesSomos from "./template/Paginas/QuienesSomos";
import RegisterForm from "./template/Register";
import ContactoPage from "./template/Paginas/Contacto";
import ComoComprar from "./template/Paginas/ComoComprar";
import Footer from './template/footer';

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  // console.log("")
  return (
    <Route
      {...rest}
      render={({ location }) =>
      // console.log()
        isAuthenticated ? (
          children
        ) : (
          <Navigate
            to={{
              pathname: '/login',
            }}
          />
         
        )
      }
    />
  );
}

const App = () => {
	const isAuthenticated = localStorage.getItem("token")

  console.log(isAuthenticated)

  return (
    <div style={styles.app}>
      {/*    basename="/idealbox/web2" */}
      <Router   >

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/quienes-somos" element={<QuienesSomos />} />
          <Route path="/servicios" element={<NuestrosServicios />} />
          <Route path="/como-comprar" element={<ComoComprar />} />
          <Route path="/contacto" element={<ContactoPage />} />

          <Route path="/register" element={<RegisterForm />} />

          <Route
            exact
            path="/login"
            element={ isAuthenticated ? (
              <Navigate
                to={{
                  pathname: '/mi-cuenta',
                }}
              />
            ) : (
              <Login />
            ) }
          />
          <Route
            exact
            path="/mi-cuenta/*"
            element={<MiCuenta isAuthenticated={isAuthenticated}/>}
          />
        </Routes>
        <Footer />
      </Router>
      
      
    </div>
  );
};

export default App;

const styles = {
  app: {
    padding: 0,
  },
}