import './../../css/Micuenta.css';

import { CodeSandboxOutlined, UserOutlined, FileTextOutlined, CreditCardOutlined } from '@ant-design/icons'
import { Link,     matchPath, useLocation} from 'react-router-dom';


function MenuLateral() {
    const { pathname } = useLocation();
console.log(pathname)
  return (
    <div className="menu_lateral">
          <ul>
            <li className={pathname == "/mi-cuenta" ? 'active' : '' } ><Link to={"/mi-cuenta"}><UserOutlined /> MI CUENTA</Link></li>
            <li className={pathname == "/mi-cuenta/paquetes" ? 'active' : '' } ><Link to={"/mi-cuenta/paquetes"}><CodeSandboxOutlined /> MIS PAQUETES</Link> </li>
            <li className={pathname == "/mi-cuenta/metodos-pago" ? 'active' : '' }  ><Link to={"/mi-cuenta/metodos-pago"}><CreditCardOutlined /> METODOS DE PAGO</Link> </li>
            <li className={pathname == "/mi-cuenta/comprobantes" ? 'active' : '' }  ><Link to={"/mi-cuenta/comprobantes"}><FileTextOutlined  /> COMPROBANTES</Link></li>
          </ul>
    </div>
  );
}

export default MenuLateral;
